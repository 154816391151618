<template>
   <v-dialog persistent max-width="440" v-model="dialog">
    <v-card rounded>
      <v-card-title class="d-flex justify-space-between align-center primary white--text">
        <div class="">New Assessment</div>
        <v-btn icon small @click="$emit('close')">
          <v-icon color="white" size="18">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="addForm" @submit.prevent="save" class="mt-5 mx-2">
          <label class="caption">TITLE *</label>
          <v-text-field solo v-model="form.title"
          :error-messages="errors.title"/>
          <v-row>
            <v-col cols="6">
              <label class="caption">TIME</label>
              <v-text-field type="number" solo 
              :error-messages="errors.duration_count"
              v-model="form.time"/>
            </v-col>
            <!-- <v-col cols="4">
              <label class="caption">SCORE</label>
              <v-text-field type="number" solo 
              :error-messages="errors.score"
              v-model="form.score"/>
            </v-col> -->
            <v-col cols="6">
              <label class="caption">ITEMS *</label>
              <v-text-field type="number" solo 
              :error-messages="errors.item"
              v-model="form.item"/>
            </v-col>
          </v-row>
          <!-- <label class="caption">DESCRIPTION</label>
          <v-textarea solo v-model="form.description"></v-textarea> -->
          <v-btn 
          block
          outlined 
          large 
          color="primary"
          :type="!loading?'submit': ''"
          class="mt-2">{{!loading?'Submit':'Submitting...'}}</v-btn>
        </v-form>
       
      </v-card-text>
    </v-card> 
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: ['dialog', 'uuid', 'action'],
  data: () => ({
    loading: false,
    form: { 
      title: '',
      // description: '',
      score: '',
      item: '',
      time: '',
      topic_uuid: '',
      lesson_uuid: ''
    }
  }),
  computed: {
    ...mapState('teacher', {
      errors: (state) => state.errors
    })
  },
  methods: {
    save() {
      console.log(this.form) 
      this.form.topic_uuid = this.$route.params.uuid
      this.form.lesson_uuid = this.uuid
      this.$store.dispatch(`teacher/add${this.action}AssessmentAction`, this.form)
      .then(() => {
        if(this.$errors('teacher')) {
          console.log('error')
          return
        }
        this.$emit('close')
        this.$emit('snackbar')
        this.$emit('save')
      })
    }
  }
}
</script>
<template>
  <v-dialog persistent max-width="440" v-model="dialog">
    <v-card rounded>
      <v-card-title
        class="d-flex justify-space-between align-center primary white--text"
      >
        <div class="">New Topic</div>
        <v-btn icon small @click="$emit('close')">
          <v-icon color="white" size="18">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="addForm" @submit.prevent="save" class="mt-5 mx-2">
          <label class="caption">TITLE *</label>
          <v-text-field v-model="form.title" :error-messages="errors.title" />
          <label class="caption">TOPIC TYPE *</label>
          <v-select
            v-model="form.type"
            :error-messages="errors.type"
            :items="$helpers.lessonType()"
          ></v-select>
          <label class="caption">DESCRIPTION</label>
          <v-textarea v-model="form.description"></v-textarea>
          <v-btn
            block
            large
            color="primary"
            outlined
            :type="!loading ? 'submit' : ''"
            class="mt-2"
            >{{ !loading ? "Submit" : "Submitting..." }}</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["dialog", "course_id"],
  data: () => ({
    loading: false,
    form: {
      title: "",
      description: "",
      type: "",
      course_id: "",
    },
  }),
  computed: {
    ...mapState("teacher", {
      errors: (state) => state.errors,
    }),
  },
  methods: {
    save() {
      console.log(this.form);
      this.form.course_id = this.course_id;
      this.$store.dispatch("teacher/addLessonAction", this.form).then(() => {
        if (this.$errors("teacher")) {
          console.log("error");
          return;
        }
        this.$refs.addForm.reset();
        this.$emit("close");
        this.$emit("save");
      });
    },
  },
};
</script>
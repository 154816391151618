<template>
  <v-dialog persistent max-width="440" v-model="dialog">
    <v-card rounded>
      <v-card-title
        class="d-flex justify-space-between align-center success white--text"
      >
        <div class="">Edit Topic</div>
        <v-btn icon small @click="$emit('close')">
          <v-icon color="white" size="18">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="addForm" @submit.prevent="save" class="mt-5 mx-2">
          <label class="caption">TITLE *</label>
          <v-text-field v-model="data.title" :error-messages="errors.title" />
          <label class="caption">TOPIC TYPE *</label>
          <v-select
            v-model="data.type"
            :error-messages="errors.type"
            :items="$helpers.lessonType()"
          ></v-select>
          <label class="caption">DESCRIPTION</label>
          <v-textarea v-model="data.description"></v-textarea>
          <v-btn
            block
            large
            color="success"
            :type="!loading ? 'submit' : ''"
            class="mt-2"
            >{{ !loading ? "Submit" : "Submitting..." }}</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: ["dialog", "data", 'course_id'],
  data: () => ({
    loading: false,

  }),
  computed: {
    ...mapState("teacher", {
      errors: (state) => state.errors,
    }),
  },
  methods: {
    ...mapActions('teacher', ['updateLesson']),

    save() {

      this.updateLesson({
        course_id: this.course_id,
        data: this.data
      }).then(() => {
        if(this.$errors('teacher')) return
        this.$emit("close");
        this.$emit("save");
      })
    },
  },
};
</script>

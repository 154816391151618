<template>
  <section class="topic">
    <div class="d-flex justify-left">
      <v-btn @click.stop="dialog = true" class="mb-3 caption" text outlined color="primary">
        Add Sub-Topic
      </v-btn>
    </div>
    <draggable
      ghost-class="ghost"
      class="topic-draggable"
      v-model="topics"
      @end="sortTopic"
    >
      <transition-group type="transition" name="flip-list">
        <v-expansion-panels class="my-3" v-for="item in topics" :key="item.id">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <template v-slot:default>
                <div class="d-flex flex-row">
                  <v-icon size="20" class="mr-3">mdi-cursor-move</v-icon>
                  <div class="d-flex flex-column">
                    <div class="body-2 font-weight-bold" v-text="item.title" />
                    <div
                      class="caption"
                      v-text="
                        item.type == 'v'
                          ? 'Video'
                          : item.type == 'd'
                          ? 'Document'
                          : item.type == 't'
                          ? 'Virtual Tour'
                          : 'Youtube'
                      "
                    />
                  </div>
                  <v-spacer />
                  <v-icon
                    size="20"
                    @click.stop="viewTopic(item.uuid)"
                    class="mr-3"
                  >
                    mdi-eye-outline
                  </v-icon>
                  <v-icon
                    class="mr-2"
                    size="28"
                    :color="item.status == 1 ? 'success' : ''"
                    @click.stop="status(item.id)"
                  >
                    {{
                      item.status == 1
                        ? "mdi-toggle-switch-outline"
                        : "mdi-toggle-switch-off-outline"
                    }}
                  </v-icon>
                  <v-icon
                    size="20"
                    @click.stop="remove(item.id)"
                    class="mr-2"
                    color="danger"
                  >
                    mdi-delete-outline
                  </v-icon>
                </div>
              </template>
            </v-expansion-panel-header>
            <v-divider />
            <v-expansion-panel-content>
              <v-card
                class="ma-5"
                v-for="assessment in item.assessments"
                :key="assessment.id"
              >
                <v-card-text class="font-weight-bold d-flex align-center">
                  <v-icon size="18" left>mdi-file-outline</v-icon>
                  {{ assessment.title }}
                  <v-spacer></v-spacer>

                  <v-btn
                    fab
                    x-small
                    icon
                    color="success"
                    class="mr-2"
                    :to="{
                      name: 'Teacher Assessment',
                      params: { uuid: assessment.uuid },
                    }"
                  >
                    <v-icon size="18">mdi-open-in-new</v-icon>
                  </v-btn>

                  <v-btn
                    x-small
                    fab
                    elevation="0"
                    class=""
                    @click="remove_assessment(assessment.id)"
                  >
                    <v-icon size="18" color="danger">mdi-delete-outline</v-icon>
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </transition-group>
    </draggable>

    <AddTopic
      :dialog="dialog"
      :lesson_id="lesson_id"
      :course_id="course_id"
      @save="
        snack = true;
        text = 'Topic Saved!';
      "
      @close="dialog = !dialog"
    />

    <snackbar
      :snack="snack"
      :text="text"
      timeout="2000"
      @close="snack = !snack"
    />
  </section>
</template>

<script>
import AddTopic from "./AddTopic.vue";
import draggable from "vuedraggable";
export default {
  props: ["topics", "lesson_id", "course_id"],
  components: {
    AddTopic,
    draggable,
  },
  data: () => ({
    dialog: false,
    snack: false,
    text: "",
  }),
  methods: {
    viewTopic(uuid) {
      this.$router.push({
        name: "Teacher Topic",
        params: { uuid: uuid },
      });
    },
    sortTopic() {
      this.$store.dispatch("teacher/sortTopicsAction", {
        sorted: this.topics,
      });
    },
    status(id) {
      this.$store.dispatch("teacher/statusTopicAction", {
        topic_id: id,
        course_id: this.course_id,
      });
    },
    remove(id) {
      if (confirm("Delete this Sub Topic ?")) {
        this.$store
          .dispatch("teacher/deleteTopicAction", {
            topic_id: id,
            course_id: this.course_id,
          })
          .then(() => {
            this.snack = true;
            this.text = "Sub Topic Deleted";
          });
      }
    },
    remove_assessment(id) {
      if (confirm("Delete this Assessment ?")) {
        this.$store
          .dispatch("teacher/deleteAssessmentAction", {
            assessment_id: id,
            course_id: this.course_id,
          })
          .then(() => {
            this.snack = true;
            this.text = "Assessment Deleted";
          });
      }
    },
  },
};
</script>

<style>
</style>
<template>
  <v-form @submit.prevent="update">
    <label class="caption">TITLE *</label>
    <v-text-field v-model="course.title" :error-messages="errors.title" />
    <!-- <v-row>
      <v-col cols="6">
        <label class="caption">DURATION TYPE *</label>
        <v-select
          :items="$helpers.durations()"
          v-model="course.duration_type"
          :error-messages="errors.duration_type"
        />
      </v-col>
      <v-col cols="6">
        <label class="caption">DURATION COUNT *</label>
        <v-text-field
          type="number"
          v-model="course.duration_count"
          :error-messages="errors.duration_count"
        />
      </v-col>
    </v-row> -->
    <label class="caption">DESCRIPTION</label>
    <vue-editor v-model="course.description"></vue-editor>
    <div class="d-flex justify-end mt-5">
      <v-btn type="submit" color="red" dark>Save Changes</v-btn>
    </div>
  </v-form>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["course"],
  computed: {
    ...mapState("teacher", {
      errors: (state) => state.errors,
    }),
  },
  methods: {
    update() {
      console.log(this.course);
      this.$store
        .dispatch("teacher/updateCourseAction", this.course)
        .then(() => {
          if (this.$errors("teacher")) return;

          this.$emit("update");
        });
    },
  },
};
</script>

<style>
</style>
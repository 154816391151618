<template>
  <section>
    <v-row v-if="!loading">
      <v-col cols="12" xl="8" lg="10">
        <v-card>
          <v-card-title class="d-flex align-center" style="height: 80px">
            <v-btn
              large
              icon
              class="mr-3 mt-1"
              :to="{ name: 'Teacher Courses' }"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <div class="text-h6">
              {{ course.title }}
            </div>
            <v-spacer />
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row class="">
              <v-col cols="12" md="8">
                <v-tabs v-model="tab" color="primary">
                  <v-tab v-for="item in items" :key="item" class="caption">
                    {{ item }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item v-for="item in items" :key="item">
                    <EditForm
                      v-if="item == 'Subject Details'"
                      :course="course"
                      @update="update"
                      class="ma-10"
                    />

                    <LessonBuilder v-else :course="course" class="ma-5" />
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
              <v-col cols="12" md="4" class="secondary">
                <v-card elevation="0" color="transparent">
                  <v-card-title class="body-2" :class="user.dark?'white--text': ''">
                    ACTIONS
                  </v-card-title>
                  <v-divider />
                  <v-card-text class="mt-1">
                    <v-btn outlined @click="del(course.id)" block class="mb-5" color="danger" dark>DELETE</v-btn>
                    <v-row>
                      <v-col cols="6">
                        <v-btn
                          @click="draft"
                          :disabled="course.status == 0 || course.status == 2"
                          block
                          color="warning"
                          >Draft</v-btn
                        >
                      </v-col>
                      <v-col cols="6">
                        <v-btn
                          :disabled="course.status == 1"
                          @click="publish"
                          block
                          color="success"
                          outlined
                          >Publish</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card elevation="0" class="my-5" color="transparent">
                  <v-card-title class="body-2 " :class="user.dark? 'white--text': ''">
                    THUMBNAIL
                  </v-card-title>
                  <div class="d-flex justify-center mb-5">
                    <v-card v-if="course.image">
                      <v-avatar tile width="200" height="200">
                        <v-img
                          :src="`${course.aws}/courses/${course.uuid}/image/${course.image.image}`"
                        />
                      </v-avatar>
                    </v-card>
                  </div>

                  <v-divider />
                  <v-card-text class="mt-1">
                    <label class="caption">PICTURE</label>
                    <v-file-input
                      prepend-icon=""
                      prepend-inner-icon="mdi-image"
                      solo
                      v-model="image"
                      :error-messages="
                        $store.getters['teacher/errorsGetters'].image
                      "
                    />
                    <v-btn
                      color="primary"
                      outlined
                      dark
                      v-if="!uploading"
                      block
                      @click="uploadImage"
                      >Upload</v-btn
                    >
                    <v-btn color="primary"
                    outlined dark v-else block
                      >Uploading....</v-btn
                    >
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <snackbar
      :snack="snack"
      :text="text"
      timeout="2000"
      @close="snack = false"
    />
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import EditForm from "../../components/course/EditForm.vue";
import LessonBuilder from "../../components/course/LessonBuilder.vue";

export default {
  components: {
    EditForm,
    LessonBuilder,
  },
  data: () => ({
    loading: true,
    view: "edit",
    tab: null,
    items: ["Subject Details", "Topic Builder"],
    snack: false,
    text: "",

    image: "",
    uploading: false,
  }),
  computed: {
    ...mapState("teacher", {
      course: (state) => state.course,
      user: (state) => state.user
    }),
  },
  mounted() {
    this.showCourseAction(this.$route.params.uuid).then(() => {
      this.loading = false;
    });
  },
  methods: {
    ...mapActions("teacher", [
      "showCourseAction",
      "courseStatusAction",
      "uploadImageCourseAction",
      "showLessonsAction",
      "deleteCourseAction"
    ]),

    update() {
      this.snack = true;
      this.text = "Course Updated";
    },
    publish() {
      if (confirm("Are you sure you want to publish this course?")) {
        this.courseStatusAction({
          action: 1,
          uuid: this.course.uuid,
        }).then(() => {
          this.snack = true;
          this.text = "Course Published";
        });
      }
    },
    draft() {
      if (confirm("Move to draft this course?")) {
        this.courseStatusAction({
          action: 2,
          uuid: this.course.uuid,
        }).then(() => {
          this.snack = true;
          this.text = "Course Drafted";
        });
      }
    },
    uploadImage() {
      let form = new FormData();
      console.log(this.image);
      form.append("id", this.course.id);
      form.append("image", this.image);
      this.uploading = true;
      this.uploadImageCourseAction({
        uuid: this.course.uuid,
        form: form,
      }).then(() => {
        this.uploading = false;
        this.snack = true;
        this.text = "Thumbnail Uploaded";
      });
    },

    del(id) {
      if(confirm('Are you sure you want to delete this course?\nNote this action is cannot be revert.')) {
        console.log(id);
        this.deleteCourseAction(id).then(() => {
          this.$router.push({name: 'Teacher Courses'})
        })
      }
    }
  },
  beforeDestroy() {
    this.$store.commit("teacher/lessonsMutation", []);
  },
  watch: {
    tab(val) {
      console.log(val);
      if (val == 1) {
        this.showLessonsAction(this.course.id);
      }
    },
  },
};
</script>
<template>
  <section id="lesson-builder" v-if="!loading">
    <div class="text-center my-10">
      <v-btn color="primary" @click="dialog = true">
        <v-icon left>mdi-plus</v-icon>
        New Topic
      </v-btn>
    </div>
    <draggable
      ghost-class="ghost"
      class="topic-draggable"
      v-model="lessons"
      @end="sortLesson"
    >
      <transition-group type="transition" name="flip-list">
        <v-expansion-panels class="my-2" v-for="item in lessons" :key="item.id">
          <v-expansion-panel :readonly="item.type == 'Assessment'">
            <v-expansion-panel-header>
              <template v-slot:default>
                <div class="d-flex flex-row">
                  <v-icon class="mr-3" size="20">mdi-cursor-move</v-icon>
                  <div class="d-flex flex-column">
                    <div class="body-1 font-weight-bold" v-text="item.title" />
                    <div class="caption" v-text="`${item.type}`" />
                  </div>
                  <v-spacer></v-spacer>
                  <v-icon
                    class="mr-2"
                    size="28"
                    :color="item.status == 1 ? 'success' : ''"
                    @click.stop="status(item.id)"
                  >
                    {{
                      item.status == 1
                        ? "mdi-toggle-switch-outline"
                        : "mdi-toggle-switch-off-outline"
                    }}
                  </v-icon>
                  <v-icon
                    class="mr-2"
                    size="22"
                    @click.stop="editLesson(item.id)"
                    color="primary"
                  >
                    mdi-pencil-outline
                  </v-icon>
                  <v-icon
                    size="22"
                    @click.stop="remove(item.id)"
                    class="mr-2"
                    color="danger"
                  >
                    mdi-delete-outline
                  </v-icon>
                </div>
              </template>
              <template v-slot:actions>
                <v-btn
                  v-if="item.type == 'Assessment'"
                  fab
                  x-small
                  :color="item.assessment_count == 0 ? '' : 'success'"
                >
                  <v-icon
                    @click.stop="lessonAssessment(item)"
                    size="20"
                    :class="item.assessment_count == 1 ? 'white--text' : ''"
                  >
                    {{
                      item.assessment_count == 0
                        ? "mdi-order-bool-descending-variant"
                        : "mdi-open-in-new"
                    }}
                  </v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <v-divider />
            <v-expansion-panel-content>
              <Topic
                class="mt-5"
                :course_id="course.id"
                :topics="item.topics"
                :lesson_id="item.id"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </transition-group>
    </draggable>

    <AddLesson
      :dialog="dialog"
      :course_id="course.id"
      @close="dialog = !dialog"
      @save="lessonSave"
    />

    <EditLesson
      :dialog="edit"
      @close="edit = !edit"
      @save="lessonSave"
      :data="lesson"
      :course_id="course.id"
    />

    <snackbar
      :snack="snack"
      :text="text"
      :timeout="2000"
      @close="snack = false"
    />

    <AddAssessment
      :dialog="assessment"
      :uuid="uuid"
      action="Lesson"
      @save="assessSave"
      @close="assessment = false"
    />
  </section>
</template>

<script>
import AddLesson from "./AddLesson.vue";
import EditLesson from "./EditLesson.vue";
import draggable from "vuedraggable";
import Topic from "./Topic.vue";
import AddAssessment from "../assessment/AddAssessment.vue";
import { mapActions, mapState } from 'vuex';

export default {
  props: ["course"],
  components: {
    AddLesson,
    EditLesson,
    draggable,
    Topic,
    AddAssessment,
  },
  computed: {
    lessons: {
      get() {
        return this.$store.state["teacher"].lessons;
      },
      set(val) {
        console.log(val);
        return this.$store.commit("teacher/lessonsMutation", val);
      },
    },

    ...mapState('teacher', {
      lesson: (state) => state.lesson
    })
  },
  data: () => ({
    dialog: false,
    loading: true,
    //---Add Assessment Variables
    assessment: false,
    uuid: "",
    //---
    snack: false,
    text: "",

    edit: false,
  }),
  mounted() {
    if (this.lessons) this.loading = false;
  },
  methods: {
    ...mapActions('teacher', ['showLesson']),

    sortLesson() {
      this.$store.dispatch("teacher/sortLessonsAction", {
        sorted: this.lessons,
      });
    },
    remove(id) {
      if (confirm("Delete this topic ?")) {
        this.$store
          .dispatch("teacher/deleteLessonAction", {
            lesson_id: id,
            course_id: this.course.id,
          })
          .then(() => {
            this.snack = true;
            this.text = "Topic Deleted";
          });
      }
    },
    lessonSave() {
      this.snack = true;
      this.text = "Topic Save!";
    },
    lessonAssessment(item) {
      if (item.assessment_count == 1) {
        this.$router.push({
          name: "Teacher Assessment",
          params: {
            uuid: item.assessment.uuid,
          },
        });
        return;
      }
      this.assessment = true;
      this.uuid = item.uuid;
    },
    assessSave() {
      this.$store.dispatch("teacher/showLessonsAction", this.course.id);
      this.snack = true;
      this.text = "Topic Assessment Save!";
    },
    status(id) {
      this.$store.dispatch("teacher/statusLessonAction", {
        lesson_id: id,
        course_id: this.course.id,
      });
    },
    editLesson(id) {
      // console.log(id);
      this.showLesson(id).then(() => {
        this.edit= true
      }) 
    }
  },
};
</script>
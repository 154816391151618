var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"topic"},[_c('div',{staticClass:"d-flex justify-left"},[_c('v-btn',{staticClass:"mb-3 caption",attrs:{"text":"","outlined":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.dialog = true}}},[_vm._v(" Add Sub-Topic ")])],1),_c('draggable',{staticClass:"topic-draggable",attrs:{"ghost-class":"ghost"},on:{"end":_vm.sortTopic},model:{value:(_vm.topics),callback:function ($$v) {_vm.topics=$$v},expression:"topics"}},[_c('transition-group',{attrs:{"type":"transition","name":"flip-list"}},_vm._l((_vm.topics),function(item){return _c('v-expansion-panels',{key:item.id,staticClass:"my-3"},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"d-flex flex-row"},[_c('v-icon',{staticClass:"mr-3",attrs:{"size":"20"}},[_vm._v("mdi-cursor-move")]),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"body-2 font-weight-bold",domProps:{"textContent":_vm._s(item.title)}}),_c('div',{staticClass:"caption",domProps:{"textContent":_vm._s(
                      item.type == 'v'
                        ? 'Video'
                        : item.type == 'd'
                        ? 'Document'
                        : item.type == 't'
                        ? 'Virtual Tour'
                        : 'Youtube'
                    )}})]),_c('v-spacer'),_c('v-icon',{staticClass:"mr-3",attrs:{"size":"20"},on:{"click":function($event){$event.stopPropagation();return _vm.viewTopic(item.uuid)}}},[_vm._v(" mdi-eye-outline ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"size":"28","color":item.status == 1 ? 'success' : ''},on:{"click":function($event){$event.stopPropagation();return _vm.status(item.id)}}},[_vm._v(" "+_vm._s(item.status == 1 ? "mdi-toggle-switch-outline" : "mdi-toggle-switch-off-outline")+" ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"size":"20","color":"danger"},on:{"click":function($event){$event.stopPropagation();return _vm.remove(item.id)}}},[_vm._v(" mdi-delete-outline ")])],1)]},proxy:true}],null,true)}),_c('v-divider'),_c('v-expansion-panel-content',_vm._l((item.assessments),function(assessment){return _c('v-card',{key:assessment.id,staticClass:"ma-5"},[_c('v-card-text',{staticClass:"font-weight-bold d-flex align-center"},[_c('v-icon',{attrs:{"size":"18","left":""}},[_vm._v("mdi-file-outline")]),_vm._v(" "+_vm._s(assessment.title)+" "),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"fab":"","x-small":"","icon":"","color":"success","to":{
                    name: 'Teacher Assessment',
                    params: { uuid: assessment.uuid },
                  }}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-open-in-new")])],1),_c('v-btn',{attrs:{"x-small":"","fab":"","elevation":"0"},on:{"click":function($event){return _vm.remove_assessment(assessment.id)}}},[_c('v-icon',{attrs:{"size":"18","color":"danger"}},[_vm._v("mdi-delete-outline")])],1)],1)],1)}),1)],1)],1)}),1)],1),_c('AddTopic',{attrs:{"dialog":_vm.dialog,"lesson_id":_vm.lesson_id,"course_id":_vm.course_id},on:{"save":function($event){_vm.snack = true;
      _vm.text = 'Topic Saved!';},"close":function($event){_vm.dialog = !_vm.dialog}}}),_c('snackbar',{attrs:{"snack":_vm.snack,"text":_vm.text,"timeout":"2000"},on:{"close":function($event){_vm.snack = !_vm.snack}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }